import React, { useState } from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import TextField from "@mui/material/TextField"
import { getProcessedArticles } from "../../common/utils/CommonUtils"
import "../../pages/learning-library.scss"

var Loader = require("react-loader")
export const SearchWrapper = (props) => {
  const [latest, setLatest] = useState(null)

  return (
    <StaticQuery
      query={graphql`
        query {
          allContentfulCategory {
            nodes {
              name
              slug
              id
              topics {
                id
                name
                slug
                publishOnWebsite
                educationOrder {
                  id
                  slug
                  authorName
                  contentLengthduration
                  publishOnWebsite
                  description
                  isPopular
                  authorImage {
                    file {
                      fileName
                      url
                    }
                  }
                  authorOrganization
                  titleImage {
                    file {
                      fileName
                      url
                    }
                  }
                  title {
                    title
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        let processedContents = getProcessedArticles(data.allContentfulCategory)

        if (props.topic) {
          processedContents = processedContents.filter(
            (article) => article.topicSlug === props.topic
          )
        }
        return (
          <div className="learning-lib-search">
            <div className="input-wrap">
              <img
                className="search-icon"
                src={require("../../assets/images/search_input.svg")}
                alt="Search Icon"
              />
              <TextField
                placeholder="Search Learning Library"
                id="search-input"
                className="search-input"
                variant="standard"
                onChange={(e) => {
                  const searchedValue = e.target.value.toLowerCase()
                  const searchedResult = processedContents
                    .filter((item) => {
                      return (
                        item.title.title
                          .toLowerCase()
                          .includes(searchedValue) &&
                        item.publishOnWebsite === true
                      )
                    })
                    .slice(0, 3)
                  setLatest(searchedResult)
                }}
              />
            </div>
            {latest && (
              <div className="lib-topic-wrapper">
                <div className="topic-list">
                  {latest ? (
                    latest.length > 0 ? (
                      latest.map((item) => {
                        return (
                          <div className="single-topic">
                            <div className="gre-content">
                              <Link
                                to={`/${item.categorySlug}/${item.topicSlug}/${item.slug}/`}
                                className="search-article-link"
                              >
                                {item.title.title}
                              </Link>
                            </div>
                          </div>
                        )
                      })
                    ) : (
                      <p className="search-no-record">No Record Found </p>
                    )
                  ) : (
                    <Loader
                      loaded={false}
                      options={this.state.loader.options}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )
      }}
    />
  )
}
